@import './src/assets/scss/variablen';
@import './src/assets/scss/_themify-icons';
@import './src/assets/scss/animate';
@import './node_modules/bootstrap/scss/bootstrap';
@import './src/assets/scss/et-line-icons';
@import './src/assets/scss/font-awesome';
@import './node_modules/swiper/src/swiper';
@import './node_modules/magnific-popup/src/css/main';
@import './src/assets/scss/_bootsnav';
@import './src/assets/scss/style';
@import './src/assets/scss/responsive';

//@import "fonts/_fonts.css";
//@import "fonts";
$color1: rgb(20, 100, 80);
$color2: rgb(60, 122, 209);
$color3: rgb(55, 65, 115);
$color4: rgb(254, 208, 5);

$BGH: url(./img/BGhead03.png);
$BGF: url(./img/BGfooter03.png);

@keyframes wellen {
	from {
		transform: translateX(0)
	}

	to {
		transform: translateX(-100px)
	}
}

html,
body {
	max-width: 100vw;
}


.swiper-container {
	.swiper-slide {
		img {
			max-width: 200px;
			filter: invert(15%);

			&:hover {
				filter: invert(0%);
			}
		}
	}
}


input.input-border-bottom {
	margin-bottom: 0px !important;
	padding-bottom: 0px !important;
}


.section-samall-padding {
	padding: 40px 0;
}


header a.logo img {
	max-height: 41px;
}

h6.wir_sind {
	display: inline-block;
}

.headline-flip>ol,
.headline-flip ul {
	display: inline-block;

	font-size: 23px;
	//font-weight: 700;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative
}

@media (max-width: 991.98px) {

	.headline-flip>ol,
	.headline-flip ul {
		//font-size: 30px
	}
}

@media screen and (max-width: 500px) {

	.headline-flip>ol,
	.headline-flip ul {
		display: block;
		//margin-top: 30px
	}
}

@media screen and (max-width: 400px) {

	.headline-flip>ol,
	.headline-flip ul {
		//font-size: 28px
	}
}

.headline-flip>ol>li,
.headline-flip ul>li {
	visibility: hidden;
	display: inline-block;
	border-bottom: 3px solid $color-black;
	position: absolute;
	top: -26px;
	left: 0;
	white-space: nowrap
}

@media (max-width: 991.98px) {

	.headline-flip>ol>li,
	.headline-flip ul>li {
		top: -26px
	}
}

.headline-flip>ol>li.active,
.headline-flip ul>li.active {
	visibility: visible
}

h4.startslyder {
	text-transform: uppercase;
	color: $color-white;
	font-weight: 700;
	line-height: 1.5;
}

h4.startslyder strong {
	color: $color-magenta;
}

.slider-text-bottom h4.startslyder {
	margin-bottom: 4vw;
}

@keyframes fadeItError {
	0% {
		background-color: transparent;
	}

	3% {
		background-color: $color-magenta;
	}

	50% {
		background-color: $color-magenta;
	}

	100% {
		background-color: transparent;
	}
}

.rueckruf-contact-form input.required-error {
	background-image: none !important;
	animation: fadeItError 2s ease-in-out;
}


/* ############################################
###  aus style.css entfernt 
##############################################

header a.logo img {max-height:26px;}
.footer-logo {max-height: 28px;}
*/